import React from 'react';

const Panel = ({ title, children }) => (
  <div style={styles.panel}>
    <h1 style={styles.panelTitle}>{title}</h1>
    <div>{children}</div>
  </div>
);

const SubPanel = ({ title, children }) => (
  <div style={styles.subPanel}>
    <h3 style={styles.subPanelTitle}>{title}</h3>
    <div>{children}</div>
  </div>
);

const Separator = () => (
  <div style={styles.separator}></div>
);

const Disclaimer = () => (
  <div style={styles.disclaimer}>
    <h2>Disclaimer</h2>
    <p>
      The content displayed on this website is for informational and entertainment purposes only. While we strive to provide accurate and up-to-date information, we make no express or implied representations or warranties regarding the completeness, accuracy, reliability, suitability, or availability of any information, products, services, or related graphics contained on this website. Any reliance on such information is strictly at your own risk.
      <br /><br />
      References to specific products or services do not constitute endorsements or affiliations with the respective entities. The views and opinions expressed on this website are those of the authors and do not necessarily reflect the official policy or position of any organization, employer, or company.
      <br /><br />
      Users are solely responsible for their interactions with the content provided. We disclaim any liability for direct, indirect, incidental, consequential, or special damages arising from the use of this website, including but not limited to loss of data or profits.
      <br /><br />
      The content on this website does not constitute legal advice and should not be relied upon as such. If you require legal or professional assistance, please consult a qualified professional.
    </p>
  </div>
);

const DataPrivacy = () => (
  <div style={styles.panel}>
    <h2>Data Privacy & Legal Compliance</h2>
    <p>
      When analyzing judicial documents using LLMs, compliance with strict data privacy and protection regulations is essential:
    </p>
    <ul>
      <li><strong>Personal Information Protection</strong>: Process all personal identifiers in accordance with <abbr title="Personal Information Protection and Electronic Documents Act">PIPEDA</abbr>, Quebec’s <abbr title="Act to modernize legislative provisions as regards the protection of personal information">Law 25</abbr> (formerly Bill 64), and applicable regulations such as <abbr title="General Data Protection Regulation">GDPR</abbr> if data is processed internationally.</li>
      <li><strong>Data Governance</strong>: Establish and enforce robust protocols for securely storing, processing, and disposing of sensitive information.</li>
      <li><strong>Access Controls</strong>: Restrict data access to authorized personnel only, implementing role-based permissions where applicable.</li>
      <li><strong>Data Minimization</strong>: Collect and process only the minimum amount of personal data necessary for analysis.</li>
      <li><strong>Secure Storage & Transmission</strong>: Utilize end-to-end encryption for both data storage and transfers to safeguard judicial documents.</li>
      <li><strong>Audit & Monitoring</strong>: Maintain comprehensive logs of data access, including timestamps and purpose of use, ensuring full traceability.</li>
    </ul>
    <p>
      <strong>Note:</strong> Each organization is responsible for adapting these practices to its specific risk assessments and legal obligations. 
    </p>
    <p>
      <strong>Disclaimer:</strong> This section is for informational purposes only and does not constitute legal advice. Always consult with a qualified legal professional before implementing AI-based analysis on judicial documents.
    </p>
</div>

);

const Header = ({ title }) => (
  <header style={styles.header}>
    <img src="LoopLensAI-main-logo.png" alt="Logo" style={styles.logo} />
    <h1 style={styles.mainTitle}>{title}</h1>
    {/* <a href="https://www..com" target="_blank" rel="noopener noreferrer" style={styles.demoLink}>
      Link
    </a> */}
  </header>
);

const Footer = ({ onToggleLanguage }) => (
  <footer style={styles.footer}>
    <div>LoopLens.AI - All Rights Reserved © 2025</div>
  </footer>
);

const PromptsGroupA = () => (
  <div>
    <h3>
      Creating effective prompts for LLMs to analyze court proceedings entails clear instruction and specifying the desired outcome.
      <br />
      Below are a set of template prompts you may use or modify to better fit your requirements:
    </h3>
    <SubPanel title="Extracting Questions and Answers">
      <code>
        Prompt: Extract all questions and answers from the provided text, and organize them in a Q&A format.
        <br /><br />
        Input:
        #Court_Proceedings_Text#
        <br /><br />
        Output: List of Questions and Answers.
      </code>
    </SubPanel>
    <SubPanel title="Analyzing Mood">
      <code>
        Prompt: Analyze and describe the mood in the provided court proceeding text, focusing on the interactions between the defense, prosecution, and the judge.
        <br /><br />
        Input:
        #Court_Proceedings_Text#
        <br /><br />
        Output: Mood analysis report.
      </code>
    </SubPanel>
    <SubPanel title="Identifying Repetitions">
      <code>
        Prompt: Identify any recurring phrases or topics mentioned by the defense, prosecution, and judge in the provided text.
        <br /><br />
        Input:
        #Court_Proceedings_Text#
        <br /><br />
        Output: List of recurring phrases or topics with frequency of occurrence.
      </code>
    </SubPanel>
    <SubPanel title="Sentiment Analysis">
      <code>
        Prompt: Conduct a sentiment analysis on the statements made by the defense, prosecution, and the judge in the provided text.
        <br /><br />
        Input:
        #Court_Proceedings_Text#
        <br /><br />
        Output: Sentiment Analysis Report.
      </code>
    </SubPanel>
    <SubPanel title="General Analysis">
      <code>
        Prompt: Conduct a general analysis of the provided court proceeding text, focusing on the key points, argument strategies, and the demeanor of the defense, prosecution, and judge.
        <br /><br />
        Input:
        #Court_Proceedings_Text#
        <br /><br />
        Output: General Analysis Report.
      </code>
    </SubPanel>
    <SubPanel title="Extracting Specific Information">
      <code>
        Prompt: Extract all mentions of the evidence presented, witness testimonies, and any objections made during the proceedings from the provided text.
        <br /><br />
        Input:
        #Court_Proceedings_Text#
        <br /><br />
        Output: Compiled information on evidence, witness testimonies, and objections.
      </code>
    </SubPanel>
    <SubPanel title="Comparing Individuals">
      <code>
        Prompt: Compare the communication styles, argument strategies, and any noticeable behavioral patterns of the defense and prosecution in the provided text.
        <br /><br />
        Input:
        #Court_Proceedings_Text#
        <br /><br />
        Output: Comparative Analysis Report.
      </code>
    </SubPanel>
  </div>
);

const PromptsGroupB = () => (
  <div>
    <h3>
      Understanding personas and biases in court proceedings can offer valuable insights into the judicial process.
      <br />
      Here are some additional prompts that can be tailored to explore these dimensions:
    </h3>

    <SubPanel title="Identifying Biases">
      <code>
        Prompt: Identify any potential biases exhibited by the defense, prosecution, or judge in the provided text, and provide examples of these biases.
        <br /><br />
        Input:
        #Court_Proceedings_Text#
        <br /><br />
        Output: List of identified biases with examples.
      </code>
    </SubPanel>
    <SubPanel title="Persona Analysis">
      <code>
        Prompt: Analyze the personas of the defense, prosecution, and judge based on their language, behavior, and interaction styles in the provided text.
        <br /><br />
        Input:
        #Court_Proceedings_Text#
        <br /><br />
        Output: Persona Analysis Report.
      </code>
    </SubPanel>
    <SubPanel title="Argumentative Strategies">
      <code>
        Prompt: Describe the argumentative strategies employed by the defense and prosecution in the provided text, and assess their effectiveness.
        <br /><br />
        Input:
        #Court_Proceedings_Text#
        <br /><br />
        Output: Analysis of argumentative strategies and effectiveness.
      </code>
    </SubPanel>
    <SubPanel title="Ethical Analysis">
      <code>
        Prompt: Assess the ethical considerations evident in the arguments presented by the defense, prosecution, and rulings made by the judge in the provided text.
        <br /><br />
        Input:
        #Court_Proceedings_Text#
        <br /><br />
        Output: Ethical Analysis Report.
      </code>
    </SubPanel>
    <SubPanel title="Tone Analysis">
      <code>
        Prompt: Analyze the tone of language used by the defense, prosecution, and judge, and relate it to their potential biases or personas.
        <br /><br />
        Input:
        #Court_Proceedings_Text#
        <br /><br />
        Output: Tone Analysis Report.
      </code>
    </SubPanel>
    <SubPanel title="Interaction Dynamics">
      <code>
        Prompt: Analyze the interaction dynamics among the defense, prosecution, and judge, and highlight any patterns that may indicate biases or persona traits.
        <br /><br />
        Input:
        #Court_Proceedings_Text#
        <br /><br />
        Output: Interaction Dynamics Report.
      </code>
    </SubPanel>
    <SubPanel title="Rhetorical Analysis">
      <code>
        Prompt: Conduct a rhetorical analysis on the statements made by the defense, prosecution, and judge to understand their persuasive strategies and potential biases.
        <br /><br />
        Input:
        #Court_Proceedings_Text#
        <br /><br />
        Output: Rhetorical Analysis Report.
      </code>
    </SubPanel>
  </div>
);

const PromptsGroupC = () => (
  <div>
    <h3>
      Identifying personal or confidential information within text data is crucial for maintaining privacy and adhering to legal standards.
      <br />
      The following prompts could be utilized to instruct LLMs to search for and highlight potential leaks of sensitive information within the court proceedings text:
    </h3>

    <SubPanel title="Personal Information Identification">
      <code>
        Prompt: Identify any personal information such as names, addresses, phone numbers, social insurance numbers, or dates of birth mentioned in the provided text.
        <br /><br />
        Input:
        #Court_Proceedings_Text#
        <br /><br />
        Output: List of personal information instances with location (page, paragraph, line).
      </code>
    </SubPanel>
    <SubPanel title="Confidential Information Identification">
      <code>
        Prompt: Identify any confidential information such as medical records, financial information, or private correspondences mentioned in the provided text.
        <br /><br />
        Input:
        #Court_Proceedings_Text#
        <br /><br />
        Output: List of confidential information instances with location (page, paragraph, line).
      </code>
    </SubPanel>
    <SubPanel title="Redaction Suggestion">
      <code>
        Prompt: Suggest redactions for any personal or confidential information found in the provided text.
        <br /><br />
        Input:
        #Court_Proceedings_Text#
        <br /><br />
        Output: Redacted version of the text or list of suggested redactions with locations.
      </code>
    </SubPanel>
    <SubPanel title="Keyword Search for Sensitive Terms">
      <code>
        Prompt: Search for common keywords associated with personal or confidential information such as 'SSN', 'account number', 'medical record', 'address', etc., and provide the context in which they are used.
        <br /><br />
        Input:
        #Court_Proceedings_Text#
        <br /><br />
        Output: List of sensitive keywords found with context and location (page, paragraph, line).
      </code>
    </SubPanel>
    <SubPanel title="Pattern Recognition for Sensitive Information">
      <code>
        Prompt: Look for patterns that resemble sensitive information such as email formats, phone number formats, financial account number formats, etc., and provide the context in which they are found.
        <br /><br />
        Input:
        #Court_Proceedings_Text#
        <br /><br />
        Output: List of potential sensitive information found through pattern recognition with context and location (page, paragraph, line).
      </code>
    </SubPanel>
    <SubPanel title="Context Analysis for Privacy Concerns">
      <code>
        Prompt: Analyze the context surrounding mentions of individuals to determine if any personal or confidential information is being disclosed inadvertently.
        <br /><br />
        Input:
        #Court_Proceedings_Text#
        <br /><br />
        Output: Context analysis report indicating potential privacy concerns.
      </code>
    </SubPanel>
    <SubPanel title="Legal Compliance Check">
      <code>
        Prompt: Check for compliance with privacy laws by identifying any unredacted personal or confidential information in the provided text, and suggest necessary redactions.
        <br /><br />
        Input:
        #Court_Proceedings_Text#
        <br /><br />
        Output: Compliance report with suggested redactions.
      </code>
    </SubPanel>
  </div>
);

// const Quote = ({ text, author }) => (
//   <div style={styles.quoteContainer}>
//     <div style={styles.quoteText}>"{text}"</div>
//     <div style={styles.quoteAuthor}>- {author}</div>
//   </div>
// );

const App = () => (

  <div style={styles.app}>
    <Header title="LoopLens.AI" />

    <Panel title="Leveraging LLMs for Judicial Hearings from the Ministère de la Justice du Québec">

      <p>The integration of Artificial Intelligence (AI) across various industries has opened up possibilities we once only dreamed of. A striking example is the judicial system of Quebec.
        <br />Thanks to OpenAI's Whisper and the available LLMs, accessing and analyzing judicial hearings has become remarkably simpler and more efficient.</p>

      <p>Here's how it works:</p>

      <ol>
        <li><strong>Request a Reproduction</strong>: Start by completing form <a href="https://www.quebec.ca/justice-et-etat-civil/services/transcription-dossier" target="_blank" rel="noreferrer">SJ-021</a> to obtain a copy of the desired judicial hearing from the Ministère de la Justice du Québec.</li>
        <li><strong>Transcription with <a href="https://openai.com/research/whisper" target="_blank" rel="noreferrer">Whisper</a></strong>: Once you have the hearing documents, Whisper will transcribe the spoken words into digital text. This conversion captures the invaluable conversations between judges, defense attorneys, crown counsels, and witnesses.</li>
        <li><strong>Deep Analysis with LLMs</strong>: Using the <a href="https://www.promptingguide.ai/" target="_blank" rel="noreferrer">correct prompting techniques</a>, LLMs can dive into the transcribed text. It can identify key components like questions, answers, mood, sentiment, and recurring patterns. This advanced analysis transforms hours of manual review into just a few minutes, significantly boosting productivity.</li>
      </ol>
    </Panel>

    <Panel title="Simple Architecture">
      <img id="LoopLensAI-flow-diagram" src="LoopLensAI.jpg" alt="Flow Diagram"></img>
    </Panel>

    <Panel title="Experience the Future of Judicial Analysis Today">
      <p>
        This AI-judicial collaboration reveals trends, potentially highlighting biases within courtroom behaviors. Identifying such patterns fosters a justice system that's transparent, accountable, and possibly even more impartial.
        <br /><br />
        This integration also creates an environment where lawyers, academics, and the general public can engage more deeply with court proceedings, making the legal system more approachable.
        <br /><br />
        As these technologies gain traction, their influence might stretch beyond Quebec's borders, signaling a shift in global legal practices. Quebec's blend of AI and law showcases the vast potential awaiting other judicial systems.
        <br /><br />
        In essence, the pairing of Whisper and an LLM with Quebec’s legal archives symbolizes more than tech meeting law. It heralds a new phase where legal complexities become clearer and justice thrives on both human intuition and technological precision. Together, we're stepping into a future where technology ensures a fairer balance on the scales of justice.
      </p>
    </Panel>

    <Separator />

    {/* <Quote
      text="AI won't replace individuals, but those who embrace AI will outpace those who don't."
      author="someone who's really bad-ass with GenAI"
    />

    <Separator /> */}

    <Panel title="Leveraging LLMs for Strategic Legal Intelligence">

      <p>When operating in a highly competitive legal landscape, law firms are continually seeking innovative strategies to gain an edge. By leveraging the power of an LLM to process and analyze hearings, firms can extract valuable insights about opposing counsel from rival firms, enabling them to craft more informed strategies and better anticipate opposing tactics.</p>

      <h2>Scenario: Analyzing Tactics of Competing Lawyers</h2>
      <ol>
        <li>
          <strong>Collection & Transcription</strong>:
          <ul>
            <li>A law firm requests copies of hearings involving lawyers from competing firms using the form SJ-021.</li>
            <li>The obtained documents are transformed into digital text using OpenAI’s Whisper.</li>
          </ul>
        </li>
        <li>
          <strong>Analysis of Representation Style</strong>:
          <p><strong>Initial Prompt</strong>: "Extract key arguments and strategies employed by [Lawyer's Name] in these hearings."</p>
          <p><em>Output</em>: A summary of primary tactics, recurring themes, and unique arguments the lawyer tends to employ.</p>
        </li>
        <li>
          <strong>Deep Dive into Cross-Examination Techniques</strong>:
          <p><strong>Secondary Prompt</strong>: "Identify the questioning style of [Lawyer's Name] during cross-examinations. Highlight any patterns."</p>
          <p><em>Output</em>: Insights into how the lawyer tackles cross-examinations, their line of questioning, aggressiveness, or other notable characteristics.</p>
        </li>
        <li>
          <strong>Response Analysis</strong>:
          <p><strong>Tertiary Prompt</strong>: "Given the extracted arguments and questions posed by [Lawyer's Name], how do witnesses or opposing counsel usually respond?"</p>
          <p><em>Output</em>: A comprehension of the effectiveness of the lawyer's strategies based on the reactions they elicit.</p>
        </li>
        <li>
          <strong>Comparative Analysis</strong>:
          <p><strong>Quaternary Prompt</strong>: "Compare the strategies and effectiveness of [Lawyer's Name] with [Another Lawyer's Name] from a different hearing."</p>
          <p><em>Output</em>: A side-by-side comparison of the tactics, questioning styles, and overall effectiveness of the two lawyers, giving a relative sense of their strengths and weaknesses.</p>
        </li>
      </ol>

      <h2>Strategic Advantage</h2>
      <p>Such in-depth, AI-driven analyses can be pivotal. Not only can a firm get a detailed view of an opposing lawyer's modus operandi, but they can also use this knowledge to:</p>
      <ul>
        <li>Train their lawyers to counter specific tactics.</li>
        <li>Craft bespoke strategies for cases where they expect to face these lawyers.</li>
        <li>Understand the strengths and weaknesses of rival firms, aiding in case assignment and strategy formulation.</li>
        <li>Offer clients an added assurance of being one step ahead in the courtroom.</li>
      </ul>

      <p>However, it's essential to approach this ethically, ensuring no rules or regulations are breached in gathering or using such information. This method should be seen as a way to understand the legal landscape better, rather than an unfair or clandestine advantage.</p>

    </Panel>

    <Panel title="Piping Multiple Prompts for Deeper Analysis">
      <p>The strength of an LLM in analyzing judicial hearings doesn't just rest on its initial interactions. By chaining or "piping" prompts, we can achieve a deeper, layered analysis of transcriptions. This involves using the output of one prompt as the basis or input for a subsequent prompt, thereby facilitating a recursive deep-dive into the content.</p>

      <h3>Example of Chaining Prompts for Deeper Analysis:</h3>

      <ol>
        <li>
          <strong>Initial Prompt</strong>: Start by asking the LLM to extract questions and answers from a given transcript.
          <ul>
            <li><em>Input</em>: "Extract all questions and answers from this hearing transcript."</li>
            <li><em>Output</em>: A structured list of Q&As from the transcript.</li>
          </ul>
        </li>
        <li>
          <strong>Secondary Analysis</strong>: Using the extracted Q&As, prompt the LLM to identify which questions were most frequently posed by the defense attorneys.
          <ul>
            <li><em>Input</em>: "From the provided Q&As, identify the most frequent questions posed by defense attorneys."</li>
            <li><em>Output</em>: A list of recurring questions asked by the defense.</li>
          </ul>
        </li>
        <li>
          <strong>Tertiary Analysis</strong>: Building on the previous output, we can then ask the LLM to analyze the sentiment or mood behind those frequently posed questions.
          <ul>
            <li><em>Input</em>: "Analyze the sentiment behind these frequently posed defense questions."</li>
            <li><em>Output</em>: Sentiment scores or descriptions (e.g., "neutral," "aggressive," "inquisitive").</li>
          </ul>
        </li>
        <li>
          <strong>Quaternary Analysis</strong>: As a further step, we might want to compare these sentiments with the reactions or answers they received.
          <ul>
            <li><em>Input</em>: "Given the sentiment of these questions, how did the witnesses or opposing counsel typically respond in sentiment?"</li>
            <li><em>Output</em>: An analysis of the sentiment in the corresponding answers or reactions, providing insights into the dynamics of the courtroom interactions.</li>
          </ul>
        </li>
      </ol>

      <p>This iterative process not only extracts raw data but also provides contextual, sentiment-based, and relational insights. Over successive prompts, an LLM can delve into finer details, revealing subtleties that might be overlooked in a broader, single-prompt analysis. The beauty of this approach lies in its adaptability – based on the outputs at each stage, users can tailor subsequent prompts to explore specific avenues of interest in the judicial hearings.</p>
    </Panel>

    <Separator />

    <PromptsGroupA></PromptsGroupA>
    <PromptsGroupB></PromptsGroupB>
    <PromptsGroupC></PromptsGroupC>

    <Separator />
    <Panel title="Considerations">
      <ul>
        <li>
          <strong>Transcription Accuracy</strong>:
          <br />
          Accuracy in transcription is crucial within a judicial context. Whisper is expected to transcribe audio recordings accurately, capturing nuances and legal jargon. At this point in time, manual corrections are anticipated to ensure the highest level of accuracy.
        </li>
        <li>
          <strong>In-depth Analysis</strong>:
          <br />
          Analyzing the transcriptions necessitates a precise understanding of the legal context. While an LLM can assist in scrutinizing and identifying trends in discourse, human expertise in law might be indispensable for a more exhaustive analysis. The utilization of Retrieval Augmented Generation (RAG) can augment the accuracy of the outputs by integrating external knowledge into the generation process.
        </li>
        <li>
          <strong>Algorithmic Bias</strong>:
          <br />
          AI tools can manifest biases from the data they were trained on. It's pivotal to inspect and, if necessary, rectify biases that could adversely affect the analysis of judicial debates.
        </li>
        <li>
          <strong>Interpretation of Results</strong>:
          <br />
          Interpretation of the analyses generated by an LLM should be approached with caution, and ideally in collaboration with legal experts to ensure accurate and meaningful insights are derived.
        </li>
      </ul>
    </Panel>
    {/* <Separator />

    <Quote
      text="AI or die"
      author="someone not afraid of changes"
    /> */}

    <Separator />
    
    <DataPrivacy />

    <Separator />

    <Disclaimer />

    <Footer />
  </div>

);

const styles = {
  app: {
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f0f0f0',
    padding: '20px 20px 60px 20px',
  },
  header: {
    backgroundColor: '#333',
    color: '#fff',
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
    position: 'sticky',
    top: '0',
    zIndex: 1000,
    // justifyContent: 'space-between',
  },
  demoLink: {
    color: '#fff',
    textDecoration: 'none',
    padding: '10px',
    borderRadius: '5px',
    backgroundColor: '#007BFF',
    transition: 'background-color 0.3s',
  },
  logo: {
    marginRight: '20px',
    width: '75px',
    height: '75px',
  },
  mainTitle: {
    margin: 0,
  },
  footer: {
    backgroundColor: '#333',
    color: '#fff',
    textAlign: 'center',
    padding: '20px',
    position: 'fixed',
    bottom: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  languageToggle: {
    background: '#fff',
    color: '#333',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  panel: {
    backgroundColor: '#ffffff',
    margin: '10px 0',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
  panelTitle: {
    color: '#333',
    marginTop: '10px',
    marginBottom: '10px',
  },
  subPanel: {
    backgroundColor: '#ffffff',
    margin: '10px 0',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
  subPanelTitle: {
    color: '#333',
    marginTop: '0',
    marginBottom: '10px',
  },
  heading: {
    color: '#333',
  },
  soundcloudPlayer: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  separator: {
    height: '1px',
    backgroundColor: '#ccc',
    margin: '20px 0',
  },
  disclaimer: {
    backgroundColor: '#ffffff',
    margin: '10px 0',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
  quoteContainer: {
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    padding: '20px',
    margin: '20px 0',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
  quoteText: {
    fontStyle: 'italic',
    marginBottom: '10px',
  },
  quoteAuthor: {
    textAlign: 'left',
    fontWeight: 'bold',
  },
};

export default App;
